import './App.css';

function App() {
  return (
    <div className="page">
      <img src="1998.svg" alt="ldod" />
            <video width="100%" height="auto" autoPlay muted loop>
        <source src="ldod.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default App;
